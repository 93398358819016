import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Index from "./page/Index";
import Portfolio from "./page/Portfolio";
import Contact from "./page/Contact";
import Terms from "./page/Terms";
import Notfound from "./page/notfound";
import Install from "./page/Install";

import Header from "./components/Header";
import Footer from "./components/Footer";
// import "./App.css";

const Root = (
    <BrowserRouter>
        <div className="flex flex-col h-screen overflow-hidden">
            <Header />
            <main className="flex-1 overflow-y-scroll bg-lightblue">
                {/* <div> */}
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/install" element={<Install />} />
                    <Route element={<Notfound />} />
                </Routes>
                {/* </div> */}
            </main>
            <Footer />
        </div>
    </BrowserRouter>
);

export default Root;
