import React from "react";

class Contact extends React.Component {
    onSubmit = () => {
        this.props.history.push("/");
    };

    render() {
        return (
            <section className="section">
                <div className="container container-pd">
                    <div className="field">
                        <div className="control">
                            <input className="input" type="text" placeholder="성명" />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <input className="input" type="text" placeholder="연락처" />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <textarea className="textarea" placeholder="문의글을 입력해주세요."></textarea>
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <label className="checkbox">
                                <input type="checkbox" /> I agree to the <a href="/">terms and conditions</a>
                            </label>
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <button className="button is-link">Submit</button>
                        </div>
                        <div className="control">
                            <button className="button is-link is-light">Cancel</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
