import React from "react";

function App() {
    return (
        <div className="flex justify-center min-h-full">
            <div className="flex items-center">
                <div className="introLogoTxt text-white text-center">INDIE IDEA</div>
            </div>
        </div>
    );
}

export default App;
