import React from "react";
import { Link } from "react-router-dom";

// const params = ({ match }) => match.params.id;
class Terms extends React.Component {
    render() {
        // console.log(params, this.props.match.params);
        const params = this.props.location.pathname || "udim";
        // console.log("params:", params);
        return (
            <section className="section">
                <div className="container container-pd">
                    <div className="title">Privacy & Terms</div>
                    <div className="tabs is-toggle is-fullwidth" id="tabs">
                        <ul>
                            <li className={params === "/terms/udim" && "is-active"} data-tab="1">
                                <Link to="/terms/udim">
                                    <span>UDIM</span>
                                </Link>
                            </li>
                            <li className={params === "/terms/mentoring" && "is-active"} data-tab="2">
                                <Link to="/terms/mentoring">
                                    <span>Mentoring</span>
                                </Link>
                            </li>
                            <li className={params === "/terms/timer" && "is-active"} data-tab="3">
                                <Link to="/terms/timer">
                                    <span>Timer</span>
                                </Link>
                            </li>
                            <li className={params === "/terms/thecamping" && "is-active"} data-tab="4">
                                <Link to="/terms/thecamping">
                                    <span>더 캠핑</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div id="tab-content">
                        <p className={params === "/terms/udim" && "is-active"} data-content="1">
                            UDIM
                        </p>
                        <p className={params === "/terms/mentoring" && "is-active"} data-content="2">
                            Mentoring
                        </p>
                        <p className={params === "/terms/timer" && "is-active"} data-content="3">
                            Timer
                        </p>
                        <p className={params === "/terms/thecamping" && "is-active"} data-content="4">
                            더 캠핑
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default Terms;
