import React from "react";

const Notfound = () => (
    <section className="section">
        <div className="container container-pd">
            <h1>Not found</h1>
        </div>
    </section>
);

export default Notfound;
