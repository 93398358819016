import React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const menus = [
        { name: "Home", link: "/" },
        { name: "Portfolio", link: "/portfolio" },
        // { name: "Contact", link: "/" },
    ];
    return (
        <header className="w-full text-center border-b border-grey bg-lightBlue-500 shadow-lg lg:p-4">
            {/* <nav className="relative shadow-lg bg-lightblue flex flex-wrap items-center justify-between px-2 p-4"> */}
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                    <NavLink activeclassname="active" to="/">
                        <p className="App-logo fl">Indie IDEA</p>
                    </NavLink>
                    <button
                        className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                        <i className="text-white fas fa-bars"></i>
                    </button>
                </div>
                <div
                    className={"lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" + (navbarOpen ? " block rounded shadow-lg sm:mb-4" : " hidden")}
                    id="example-navbar-warning"
                >
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                        {menus.map((d, idx) => {
                            return (
                                <li className="flex items-center" key={idx}>
                                    <span className="text-gray-800 hover:text-gray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                                        <span className="lg:text-white inline-block ml-2" onClick={() => setNavbarOpen(!navbarOpen)}>
                                            <NavLink activeclassname="active" to={d.link}>
                                                <span className="lg:text-white"> {d.name}</span>
                                            </NavLink>
                                        </span>
                                    </span>
                                </li>
                            );
                        })}

                        <li className="flex items-center">
                            <a
                                className="text-gray-800 hover:text-gray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                href="mailto:indieidealabs@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="lg:text-white inline-block ml-2">Contact</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* </nav> */}
        </header>
    );
}
